<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Pohon </strong><small>Edit Data Pohon</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePohon">
            <CRow>
              <CCol sm="6">
                <div class="form-group">
                <label>Publish Pohon</label>
                <select v-model="publish" placeholder="Pilihan" class="form-control">
                  <option value="" disabled selected>Pilihan</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </select>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <div class="form-group">
                <label>Nama Pohon</label>
                <input type="hidden" v-model="name"  />  
                <select v-model="kategori_pohon" placeholder="Pilih Jenis" @change="updateName" class="form-control">
                  <option value="" disabled selected>Pilih Nama Pohon</option>
                  <option v-for="item in jenis" :key="item.id_informasi_pohon" :value="item.id_informasi_pohon">{{ item.judul_informasi_pohon }}</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Tipe Pohon</label>
                <select v-model="type" placeholder="Pilih Jenis" class="form-control">
                  <option value="" disabled selected>Pilih Jenis</option>
                  <option value="pohonhidup">Pohon Hidup</option>
                  <option value="pohonditebang">Pohon Ditebang</option>
                </select>
                </div>
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="lng"
                label="Longitude"
                placeholder="Longitude Pohon"
              />  
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="lat"
                  label="Lattitude"
                  placeholder="Lattitude Pohon"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="tinggi"
                  label="Tinggi Pohon"
                  placeholder="Tinggi Pohon"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="diameter"
                  label="Diameter Pohon"
                  placeholder="Diameter Pohon"
                />  
              </CCol> 
              <CCol sm="4">
                <CInput
                  v-model="lebar_tajuk"
                  label="Lebar Tajuk Pohon"
                  placeholder="Lebar Tajuk Pohon"
                />  
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="carbon"
                  label="Carbon Pohon"
                  placeholder="Carbon Pohon"
                />  
              </CCol> 
              <CCol sm="3">
                <CInput
                  v-model="carbondioksida"
                  label="CO2 Pohon"
                  placeholder="CO2 Pohon"
                />  
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="biomassa"
                  label="Biomasa Pohon"
                  placeholder="Biomasa Pohon"
                />  
              </CCol>
              <CCol sm="3">
                <CInput
                  v-model="oksigen"
                  label="Oksigen Pohon"
                  placeholder="Oksigen Pohon"
                />  
              </CCol>
              <!-- <CCol sm="6">
                <div class="form-group">
                  <label>Foto Pohon </label><br/>                  
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                    :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                    <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
                  </div>
                </div>  
              </CCol> -->
              <CCol sm="12">
                <CTextarea
                  v-model="address"
                  label="Alamat Pohon"
                  placeholder="Alamat Pohon"
                />  
              </CCol>   
            </CRow>
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/pohon">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      :show.sync="modalError"
      
    >
      <b>Error</b>! Data Pohon gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      publish : "",
      name : "",
      no_reg_pohon : "",
      type : "",
      lng : "",
      lat : "",
      tinggi : "",
      diameter : "",
      lebar_tajuk : "",
      carbon : "",
      carbondioksida : "",
      biomassa : "",
      // foto : "",
      oksigen: "",
      address : "",
      kategori_pohon: "",
      pohon : [],
      jenis : [],
      selected : [], // Must be an array reference!
      show: true,
      modalError: false,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showPohon();
  this.loadData();
  },
  methods: {

    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"informasipohons")
        .then((response) => {
            this.jenis = response.data;
      })
    },
    
    updateName(event) {
      // Find the selected item based on the selected category
      const selectedItem = this.jenis.find(item => item.id_informasi_pohon == event.target.value);
      console.log(event.target.value, JSON.stringify(this.jenis), selectedItem, selectedItem.judul_informasi_pohon)
      
      // Update the name variable if the item is found
      if (selectedItem) {
        this.name = selectedItem.judul_informasi_pohon; // Store the name of the selected tree
      } else {
        this.name = ''; // Reset if no item is found
      }
    },
    updatePohon: function(event){
      // Simple POST request with a JSON body using axios
      const pohon = { 
                          publish:this.publish,
                          name:this.name,
                          no_reg_pohon: this.no_reg_pohon, 
                          type: this.type, 
                          lng: this.lng, 
                          lat: this.lat, 
                          tinggi: this.tinggi, 
                          diameter: this.diameter, 
                          lebar_tajuk: this.lebar_tajuk, 
                          carbon: this.carbon, 
                          carbondioksida: this.carbondioksida, 
                          biomassa: this.biomassa, 
                          oksigen: this.oksigen, 
                          // foto: this.foto,
                          address: this.address, 
                          kategori_pohon: this.kategori_pohon,
                          id: this.$route.params.id
                          };
      // alert(JSON.stringify(pekerjaan));
      axios.post(process.env.VUE_APP_BASE_URL+"pohon/"+this.$route.params.id, pohon)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.data.status==='success'){
              this.$router.push('/pohon');
            }
            else{
              this.modalError = true;
            }
            
        })
    },
    showPohon: function(event){
      // Simple POST request with a JSON body using axios
      const pohon = { 
                          publish:this.publish,
                          name:this.name,
                          no_reg_pohon: this.no_reg_pohon, 
                          type: this.type, 
                          lng: this.lng, 
                          lat: this.lat, 
                          tinggi: this.tinggi, 
                          diameter: this.diameter, 
                          lebar_tajuk: this.lebar_tajuk, 
                          carbon: this.carbon, 
                          carbondioksida: this.carbondioksida, 
                          biomassa: this.biomassa, 
                          oksigen: this.oksigen, 
                          // foto: this.foto,
                          kategori_pohon: this.kategori_pohon,
                          address: this.address 
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"pohon/"+this.$route.params.id)
        .then((response) => {
              const data = response.data.data;
              this.publish=data.publish;
              this.name=data.name;
              this.no_reg_pohon=data.no_reg_pohon;
              this.type=data.type;
              this.lng=data.lng;
              this.lat=data.lat;
              this.tinggi=data.tinggi;
              this.diameter=data.diameter;
              this.lebar_tajuk=data.lebar_tajuk;
              this.carbon=data.carbon;
              this.carbondioksida=data.carbondioksida;
              this.biomassa=data.biomassa;
              this.oksigen=data.oksigen;
              this.kategori_pohon=data.kategori_pohon;
              this.address=data.address;
            
        })
    }
  }
}
</script>